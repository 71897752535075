const cryptoJS = require("crypto-js");

// Function to encrypt the password
// SHA265(password)
const encryptPassword = (password) => {
  return new Promise((resolve, reject) => {
    try {
      const hashedPassword = cryptoJS.SHA256(password).toString();
      resolve(hashedPassword);
    } catch (error) {
      reject(error);
    }
  });
};

module.exports = {
  encryptPassword,
};
