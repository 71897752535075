import axios from "axios";
import { encryptPassword } from "../../utils/passwords";
import { history } from "../../utils/history";

export default class ApiClient {
  constructor() {
    this.csrfToken = null;
    this.authToken = null;
    this.userID = null;
    this.api_url = `${process.env.REACT_APP_URL}/api/v1/`;
    this.client = axios.create({
      baseURL: this.api_url,
      timeout: 60000, // 1 Minute timeout
    });

    // Request intercepter (CSRF and Auth Token)
    this.client.interceptors.request.use(
      (config) => {
        if (this.csrfToken) {
          config.headers["x-xsrf-token"] = this.csrfToken;
        }
        if (this.authToken) {
          config.headers["Authorization"] = `Bearer ${this.authToken}`;
        }
        return config;
      },
      (error) => {
        console.error("Error during authentication:", error.message);
        history.navigate("/error");
      }
    );

    // Response intercepter (Expired auth token, invalid CSRF token)
    this.client.interceptors.response.use(
      (response) => response,
      (error) => {
        try {
          if (
            error.response.status === 500 ||
            error.response.config.url === "/auth/csrf-token" ||
            error.response.config.url === "/auth/logout"
          ) {
            // Non recoverable error
            history.navigate("/error");
            return Promise.reject(error);
          } else if (
            error.response.status === 401 ||
            error.response.status === 403
          ) {
            // If the error is due to an expired refresh token, navigate to login
            if (error.response.config.url === "/auth/auth-token") {
              console.error("Error:", error.message);
              this.client.post(`/auth/logout`);
              history.navigate("/login");
              return Promise.reject(error);
            }

            // Store the original request
            const originalRequest = error.config;

            return this.client
              .post("/auth/auth-token")
              .then((response) => {
                this.authToken = response.data.authToken;
                this.userID = response.data.userID;

                // Update the auth token in the original request
                originalRequest.headers["Authorization"] =
                  "Bearer " + this.authToken;

                // Retry the original request
                return this.client(originalRequest);
              })
              .catch((error) => {
                console.error("Error:", error.message);
                this.client.post(`/auth/logout`);
                history.navigate("/login");
                return Promise.reject(error);
              });
          } else if (
            error.response.status === 400 &&
            error.response.data &&
            (error.response.data instanceof Blob
              ? error.response.data.size === 32
              : error.response.data.message === "Invalid XSRF token")
          ) {
            /* 
              If the request was made to retrieve an image, the response will be of type Blob 
              even though it contains the error as JSON data. In this case we only check the size of the response.
              {"message":"Invalid XSRF token"} is 32 bytes in size which at this point is a good enough 
              indicator that the error is due to an invalid CSRF token. 
            */

            const originalRequest = error.config; // Store the original request

            // If the error is due to an invalid CSRF Token (e.g. switching tabs), refresh the CSRF token
            return this.client
              .get("/auth/csrf-token")
              .then((response) => {
                this.csrfToken = response.data.csrfToken;

                // Update the CSRF token in the original request
                originalRequest.headers["x-xsrf-token"] = this.csrfToken;

                // Retry the original request
                return this.client(originalRequest);
              })
              .catch((error) => {
                history.navigate("/error");
                return Promise.reject(error);
              });
          } else {
            return Promise.reject(error);
          }
        } catch (error) {
          console.error("Error:", error.message);
          history.navigate("/login");
          return Promise.reject(error);
        }
      }
    );
  }

  // First things first - get the CSRF token
  getCsrfToken = async () => {
    try {
      const response = await this.client.get("/auth/csrf-token");
      this.csrfToken = response.data.csrfToken;
      //console.log("CSRF token fetched:", this.csrfToken);
    } catch (error) {
      console.error("Error fetching CSRF token:", error);
    }
  };

  // Next, try to get an auth token using the refresh token (if present)
  refreshAuthToken = async () => {
    try {
      const response = await this.client.post("/auth/auth-token");
      if (response.status === 200 && response.data.authToken) {
        this.authToken = response.data.authToken;
        this.userID = response.data.userID;
        //console.log("New authToken:", this.authToken);
      }
    } catch (error) {
      //console.error("Error during auth token refresh:", error);
      throw error;
    }
  };

  /* UNAUTHENTICATED ROUTES */
  signup = async (data) => {
    let _data = { ...data };

    // Encrypt password
    await encryptPassword(data.password)
      .then((hashedPassword) => {
        _data.password = hashedPassword;
      })
      .catch((error) => {
        console.error("Error during password encryption:", error);
      });

    return this.client.post("/users/signup", _data);
  };

  login = async (data) => {
    let _data = { ...data };

    // Encrypt password
    await encryptPassword(data.password)
      .then((hashedPassword) => {
        _data.password = hashedPassword;
      })
      .catch((error) => {
        console.error("Error during password encryption:", error);
      });

    return this.client
      .post("/auth/login", _data)
      .then((response) => {
        if (response.status === 200 && response.data.authToken) {
          this.authToken = response.data.authToken;
          this.userID = response.data.userID;
        }
        return response;
      })
      .catch((error) => {
        console.error("Error during login:", error);
        throw error;
      });
  };

  getResetToken = (email) => {
    return this.client.get(`/auth/password-token/${email}`);
  };

  resetPassword = async (data) => {
    let _data = { ...data };

    // Encrypt password
    await encryptPassword(data.passwordNew)
      .then((hashedPassword) => {
        _data.passwordNew = hashedPassword;
      })
      .catch((error) => {
        console.error("Error during password encryption:", error);
      });

    return this.client.patch(`/auth/password-reset`, _data);
  };

  confirmEmail = (email) => {
    return this.client.get(`/auth/email-confirmation/${email}`);
  };

  changeEmail = (email) => {
    return this.client.get(`/auth/reset-email-confirmation/${email}`);
  };

  /* AUTHETICATED ROUTES */

  /* USER */
  getUser = () => {
    return this.client.get(`/users/${this.userID}`);
  };

  updateUser = (data) => {
    return this.client.patch(`/users/${this.userID}`, data);
  };

  setNewEmail = (data) => {
    return this.client.patch(`/users/credentials/${this.userID}`, data);
  };

  deleteUser = () => {
    return this.client.delete(`/users/${this.userID}`);
  };

  setNewPassword = async (data) => {
    let _data = { ...data };
    let passwordOld = _data.passwordOld;
    let passwordNew = _data.passwordNew;

    // Encrypt password
    const encryptPasswords = async (password) => {
      try {
        const encryptedPassword = await encryptPassword(password);
        return encryptedPassword;
      } catch (error) {
        console.error("Error during password encryption:", error);
        throw error;
      }
    };

    // Encrypting old password
    const encryptedOldPassword = await encryptPasswords(passwordOld);
    _data.passwordOld = encryptedOldPassword;

    // Encrypting new password
    const encryptedNewPassword = await encryptPasswords(passwordNew);
    _data.passwordNew = encryptedNewPassword;

    return this.client.patch(`/users/credentials/${this.userID}`, _data);
  };

  getProfilePicture = (fileName) => {
    return this.client.get(`/users/profile-picture?fileName=${fileName}`, {
      responseType: "blob",
    });
  };

  getGdprExport = () => {
    return this.client.get(`/auth/gdpr-export/${this.userID}`);
  };

  logout = async () => {
    try {
      const response = await this.client.post("/auth/logout");
      // If logout was successful, remove authToken and userID
      if (response.status === 200) {
        this.authToken = null;
        this.userID = null;
      }
      return response;
    } catch (error) {
      throw error;
    }
  };

  logoutAll = () => {
    try {
      const response = this.client.post(`/auth/logout-all`);
      // If logout was successful, remove authToken and userID
      if (response.status === 200) {
        this.authToken = null;
        this.userID = null;
      }
      return response;
    } catch (error) {
      throw error;
    }
  };

  recapEmail = () => {
    return this.client.get(`/recap`);
  };

  /* DIVE */
  getDives = () => {
    return this.client.get(`/dives`);
  };

  createDive = (data) => {
    return this.client.post(`/dives`, data);
  };

  updateDive = (diveID, data) => {
    return this.client.patch(`/dives/${diveID}`, data);
  };

  deleteDive = (diveID) => {
    return this.client.delete(`/dives/${diveID}`);
  };

  getDiveImage = (fileName) => {
    return this.client.get(`/dives/images?fileName=${fileName}`, {
      responseType: "blob",
    });
  };

  /* LOCATION */
  createLocation = (data) => {
    return this.client.post(`/locations`, data);
  };

  updateLocation = (locationID, data) => {
    return this.client.patch(`/locations/${locationID}`, data);
  };

  resolveCoordinates = (lat, lng) => {
    return this.client.get(
      `/locations/resolve-coordinates?lng=${lng}&lat=${lat}`
    );
  };
}
