import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import Loading from "./components/Loading";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import ApiProvider from "./contexts/ApiProvider"; // Context provider for the Underwaterly API

import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

// Lazy load the App component
const App = React.lazy(() => import("./App"));

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <ApiProvider>
      {/* Context provider for the Underwaterly API so that it can be used in all sub-components */}
      <Suspense fallback={<Loading />}>
        <App />
      </Suspense>
    </ApiProvider>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
