import React from "react";
import logo from "../assets/images/logo/icon_app.svg";

export default function Loading() {
  return (
    <div className="loading">
      <img src={logo} alt="App Logo" className="loading-logo" />
    </div>
  );
}
