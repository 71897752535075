import { createContext, useContext } from "react";
import ApiClient from "../services/underwaterly/apiClient";

// Create a new context for the API
const ApiContext = createContext();

/* 
    Inspired by https://blog.miguelgrinberg.com/post/the-react-mega-tutorial-chapter-6-building-an-api-client
    - This component enables the API to be used throughout the application without having to pass it down as props
    
    Usage: 
    - App.js: Wrap the entire application in the ApiProvider component
    - Any component: Import the useApi hook and call it to get the API instance
      e.g. `import { useApi } from "../contexts/ApiProvider";
            const api = useApi();
            api.signUp({email: "test@test.com", ...}).then((r) => console.log(r)).catch((e) => console.log(e));`
*/

// This component serves as a provider for the API context
export default function ApiProvider({ children }) {
  const api = new ApiClient(); // Instantiate an instance of the ApiClient

  // Provide the API instance to the children components through the context
  return <ApiContext.Provider value={api}>{children}</ApiContext.Provider>;
}

// Custom hook to consume the API context
export function useApi() {
  return useContext(ApiContext);
}
